import React, { useRef, useEffect } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import CtaBox from './CtaBox'

const ServicesHero = ({description}) => {

  const { car, shapesImage } = useStaticQuery(graphql`
  {
    car: file(name: {eq: "mechanic-92"}){
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapesImage: file(name: {eq: "services-shapes"}){
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) { 
          ...GatsbyImageSharpFluid
        }
      }
    }
  } 
  `)

  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top;
    const offset = window.pageYOffset - posY;
    set({ offset });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const ref = useRef(null)

  const calc = offset => `translateY(${offset * 0.1}px)`

  return (
    <div style={{ height: "calc(100vh - 35px)", width: "100%", position: "relative", background: "#E8E7EE" }}>

      {/* Parallax layer */}
      <StyledParallaxWrapper style={{ display: "initial" }}>
        <animated.div ref={ref} style={{ transform: offset.interpolate(calc) }} className='parallaxWrapper-inner'>
          <StyledBackground tag='div' fluid={car.childImageSharp.fluid} position />
        </animated.div>
      </StyledParallaxWrapper>
      
      {/* Shaped background layer */}
      <StyledBackground fluid={shapesImage.childImageSharp.fluid} />

      {/* Top content layer */}
      <StyledTopContent>
        <CtaBox title="Our Services" content={description} />
      </StyledTopContent>

    </div>
  )
}

const StyledTopContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
`

const StyledBackground = styled(BackgroundImage)`
  height: 100%;
  width: 95%;
  background-position: top right ;
`

export const StyledParallaxWrapper = styled.div`
  overflow:hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  .parallaxWrapper-inner {
    height: 100%;
    width: 100%;
  }
`


export default ServicesHero
