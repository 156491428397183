import React from "react"
import Button from "./Button"
import styled from "styled-components"
import theme from "../theme"
import { FaPhoneAlt } from "react-icons/fa"

const CtaBox = ({ title, content }) => {
  return (
    <StyledBox>
      <h1>{title}</h1>
      <p>{content}</p>
      <Button to="/book-appointment">Book Appointment</Button>
      <a href="tel:098277563" className="lg-link-text">
        <FaPhoneAlt style={{ fontSize: "50px", marginRight: "10px" }} />
        (09) 8277563
      </a>
    </StyledBox>
  )
}

const StyledBox = styled.div`
  color: white;
  background: ${theme.primaryColor};
  width: 90%;
  max-width: 600px;
  box-sizing: border-box;
  padding: 50px 30px;

  .lg-link-text {
    display: block;
    color: white;
    text-decoration: none;
    font-size: 35px;
    font-family: "Khand", sans-serif;
    font-weight: bold;
    font-style: italic;
    margin: 50px 0;
  }

  h1 {
    font-family: "Khand", sans-serif;
    font-size: 40px;
    margin: 0;
    margin-bottom: 40px;
    position: relative;

    &::after,
    &::before {
      content: "";
      width: 20%;
      position: absolute;
      height: 3px;
      background: white;
      left: 0;
      bottom: 0;
      transform: translateX(2px);
    }
    &::after {
      transform: translateX(0);
      bottom: -6px;
    }
  }
  p {
    font-size: 14px;
    line-height: 2em;
    font-weight: 700;
    white-space: pre-line;
    margin-bottom: 50px;
  }

  @media (min-width: 1120px) {
    transform: translateX(30%);
    padding: 80px 60px;
    h1 {
      font-size: 80px;
    }
    .lg-link-text {
      font-size: 60px;
    }
  }
`

export default CtaBox
