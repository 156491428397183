import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import Button from '../components/Button'

const ServicesList = ({ title, services }) => {
  console.log({ title, services })
  return (
    <StyledList>
      <div className="wrapper">
        <h1>{title}</h1>
        <ul>
          {services.map(service => <li><span>{service}</span></li>)}
        </ul>
        <div className="content-center mt-md">
          <Button to="/book-appointment">Book online</Button>
        </div>
      </div>

    </StyledList>
  )
}

const StyledList = styled.section`

  padding: 54px 0;
  color: white;
  font-weight: bold;
  text-transform: uppercase;

  &:nth-of-type(3n+1) {
    background: #021827;
  }
  &:nth-of-type(3n+2) {

    background: #00081e;
  }
  &:nth-of-type(3n+3) {
    background: #002742;

  }
  .wrapper {
    margin: auto;
    max-width: 1060px;
    padding: 0 20px;
  }

  h1 {
    font-family: "Khand";
    position: relative;
    font-size: 60px;

    &::after, &::before {
      content: "";
      width: 100px;
      position: absolute;
      height: 3px;
      background: ${theme.primaryColor};
      left: 0;
      bottom: 0;
      transform: translateX(2px);
    }
    &::after {
      transform: translateX(0);
      bottom: -6px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  li {
    width: 300px;
    box-sizing: border-box;
    padding-left: 50px;
    margin-bottom: 50px;
    position: relative;

    &::before{
      content: "";
      height: 8px;
      width: 8px;
      background: ${theme.primaryColor};
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media(max-width: 800px) {
    ul {
      align-items: center;
      justify-content: center;
    }
    li {
      width: 100%;
    }
    h1 {
      font-size: 30px;
      padding-bottom: 20px;
    }
  }

`

export default ServicesList
