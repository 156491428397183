import React from "react"
import Layout from "../components/layout"
import ServicesHero from "../components/ServicesHero"
import { graphql, useStaticQuery } from "gatsby"
import ServicesList from "../components/ServicesList"
import Seo from "../components/seo"

const Services = () => {
  const { page } = useStaticQuery(graphql`
    {
      page: contentfulServicesPage {
        servicesHeroDescription {
          servicesHeroDescription
        }
        servicesSections {
          title
          services
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Services for Cars & Light Trucks | Anl" />
      <ServicesHero
        description={page.servicesHeroDescription.servicesHeroDescription}
      />
      {page.servicesSections.map(sec => (
        <ServicesList title={sec.title} services={sec.services} />
      ))}
    </Layout>
  )
}

export default Services
